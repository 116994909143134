import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { PaymentIntent, loadStripe } from '@stripe/stripe-js'

import { SubmitHandler, useForm } from 'react-hook-form'
import MainLayout from '../../layouts/main'
import TextField from '../../components/form/form-text'
import FormTextarea from '../../components/form/form-textarea'
import SelectField from '../../components/form/form-select'
import { holidays, hours, minutes, prefectures } from '../../config/opstions'
import FieldLabel from '../../components/form/form-label'
import LoadingDialog from '../../components/dialog/loading-dialog'
import axios from 'axios'
import defaultUserImg from '../../images/default_user.png'
import StripeCheckoutForm from '../../components/form/stripe-form'
import { useNavigate } from 'react-router-dom'

interface IFormInput {
  email: string
  password: string
  name: string
  prefecture: string
  address: string
  building: string
  tel: string
  introduce: string
  business_start_hour: string
  business_start_minute: string
  business_end_hour: string
  business_end_minute: string
  twitter: string
  facebook: string
  youtube: string
  instagram: string
}

interface TitleProps {
  text: string
}

const Title: React.FC<TitleProps> = ({ text }) => {
  return <h2 className="font-bold text-lg mb-4">{text}</h2>
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY || '')

const RegisterInputPage = () => {
  const [isPaymentDialog, setIsPaymentDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [image, setImage] = useState<string | null>(null)
  const [base64Image, setBase64Image] = useState<string | null>(null)
  const [selectHolidays, setSelectHolidays] = useState<string[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [amount, setAmount] = useState<number | null>(null)
  const [monthlyAmount, setMonthlyAmount] = useState<number | null>(null)
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null)
  const [customerId, setCustomerId] = useState<string | null>(null)

  const loadStripe = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ROOT_URL}/stripe/create`,
      {}
    )
    console.log(response)

    setAmount(response.data.amount)
    setMonthlyAmount(response.data.monthly_amount)
    setPaymentIntentId(response.data.paymentIntent)
    setCustomerId(response.data.customer)

    setIsLoading(false)
  }

  useEffect(() => {
    loadStripe()
  }, [])

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormInput>()

  const allFields = watch()

  /**
   * 入力内容を確認して問題あるかどうかを返却する
   * @param data
   */
  const checkInput = async (data: any): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT_URL}/auth/register/guild/check`,
        data
      )

      return response.data.success
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          let message = ''
          if (error.response.data.msg) {
            message = error.response.data.msg
          } else {
            error.response.data.errors.map((m: string) => {
              message += m + '\n'
            })
          }
          alert(message)
          return false
        } else {
          console.error('Error', error.message)
        }
      } else {
        // 非Axiosのエラー
        console.error('Error', error)
      }
      return false
    }
  }

  const goCompletePage = () => {
    navigate('/register/complete')
  }

  /**
   * 会員登録と決済履歴の保存
   * @param paymentIntent
   */
  const registerUser = async (paymentIntent: PaymentIntent): Promise<void> => {
    setIsLoading(true)
    const data = getRequestData()
    const requestData = {
      ...data,
      pid: paymentIntent.id,
      cid: customerId,
      amount: paymentIntent.amount,
      status: 'PaymentIntentsStatus.Succeeded',
      currency: paymentIntent.currency,
      description: `{description: ${paymentIntent.description}, status: ${paymentIntent.status}}`,
    } as IFormInput

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ROOT_URL}/auth/register/guild/payment`,
        requestData
      )

      console.log(response)
      setIsLoading(false)
      goCompletePage()
    } catch (error) {
      alert(
        'エラーが発生しました。決済が完了している恐れがあります。お手数ですがお問い合わせください'
      )
      setIsLoading(false)
      return
    }
  }

  /// 画像アップロード
  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const file = e.target.files[0]
    setImage(URL.createObjectURL(file))

    // FileReaderを使って画像をBase64に変換
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const base64String = reader.result as string
      setBase64Image(base64String)
    }
    reader.onerror = (error) => {
      console.error('Error converting image to Base64', error)
    }
  }

  /// 画像削除
  const handleImageDelete = () => {
    setImage(null)
    setBase64Image(null)
  }

  const getRequestData = (): IFormInput => {
    const data = allFields
    return {
      ...data,
      profile_image_base64: base64Image,
      regular_holidays: selectHolidays,
      main_genre_code: 'maingenre1',
      sub_genre_code: 'subgenre1',
    } as IFormInput
  }

  const onSubmit: SubmitHandler<IFormInput> = async () => {
    setIsLoading(true)
    const requestData = getRequestData()

    // まずは確認
    const isCheck = await checkInput(requestData)
    setIsLoading(false)
    if (isCheck) {
      setIsPaymentDialog(true)
    }
  }

  const content = (
    <>
      {isLoading && <LoadingDialog></LoadingDialog>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title text={'プロフィール画像'}></Title>
        <div className="mb-16">
          <div className="flex flex-wrap items-center max-sm:block">
            <div
              className="w-32 h-32 border-2 rounded-full bg-center font-bold bg-cover max-sm:mx-auto max-sm:mb-4"
              style={{ backgroundImage: `url(${image ?? defaultUserImg})` }}
            ></div>
            <div className="flex max-sm:justify-center">
              <button
                type="button"
                className="py-2 ml-8 max-sm:ml-0 rounded-md w-40 text-center text-sm text-white bg-orange"
                onClick={() => fileInputRef.current?.click()}
              >
                画像アップロード
              </button>
              {image && (
                <button
                  type="button"
                  className="py-2 ml-4 rounded-md w-32 text-center text-sm text-white bg-gray-600"
                  onClick={() => handleImageDelete()}
                >
                  削除
                </button>
              )}
            </div>
            <input
              className="hidden"
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              multiple
            />
          </div>
        </div>
        <Title text={'基本情報'}></Title>
        <div className="mb-16">
          <TextField
            label="ニックネーム"
            name="name"
            register={register}
            errors={errors}
            className="mb-4"
            required
          />
          <TextField
            label="メールアドレス"
            name="email"
            register={register}
            errors={errors}
            className="mb-4"
            required
          />
          <div className="w-40">
            <SelectField
              name="prefecture"
              label="住所"
              options={prefectures.map((p) => {
                return { value: p, label: p }
              })}
              register={register}
              className="mb-3"
              required
            />
          </div>
          <TextField
            name="address"
            register={register}
            errors={errors}
            className="mb-3"
            placeholder="市区町村・番地"
            required
          />
          <TextField
            name="building"
            register={register}
            errors={errors}
            className="mb-4"
            placeholder="建物名"
          />
          <TextField
            label="電話番号"
            name="tel"
            register={register}
            placeholder="XXX-XXXX-XXXX"
            errors={errors}
            className="mb-4"
            required
          />
          <FormTextarea
            label="紹介文"
            name="introduce"
            register={register}
            errors={errors}
            className="mb-4"
          />
          <FieldLabel label="営業時間" required />
          <div className="flex justify-start items-center">
            <div className="w-16">
              <SelectField
                name="business_start_hour"
                options={hours.map((p) => {
                  return { value: p, label: p }
                })}
                register={register}
                required
              />
            </div>
            <span className="px-2">:</span>
            <div className="w-16">
              <SelectField
                name="business_start_minute"
                options={minutes.map((m) => {
                  return { value: m, label: m }
                })}
                register={register}
                required
              />
            </div>
            <span className="px-2">-</span>
            <div className="w-16">
              <SelectField
                name="business_end_hour"
                options={hours.map((p) => {
                  return { value: p, label: p }
                })}
                register={register}
                required
              />
            </div>
            <span className="px-2">:</span>
            <div className="w-16">
              <SelectField
                name="business_end_minute"
                options={minutes.map((m) => {
                  return { value: m, label: m }
                })}
                register={register}
                required
              />
            </div>
          </div>
        </div>
        <Title text={'定休日'}></Title>
        <div className="mb-16">
          {holidays.map((option, index) => (
            <label key={`${index}-cb`} className="mr-4 inline-block">
              <input
                type="checkbox"
                value={option.value}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectHolidays([...selectHolidays, option.value])
                  } else {
                    setSelectHolidays(
                      selectHolidays.filter((h) => h !== option.value)
                    )
                  }
                }}
              />
              <span className="ml-1">{option.label}</span>
            </label>
          ))}
        </div>
        <Title text={'SNS'}></Title>
        <div className="mb-16">
          <TextField
            label="Twitter"
            name="twitter"
            register={register}
            errors={errors}
            className="mb-4"
          />
          <TextField
            label="Facebook"
            name="facebook"
            register={register}
            errors={errors}
            className="mb-4"
          />
          <TextField
            label="YouTube"
            name="youtube"
            register={register}
            errors={errors}
            className="mb-4"
          />
          <TextField
            label="Twitter"
            name="twitter"
            register={register}
            errors={errors}
            className="mb-4"
          />
          <TextField
            label="Instagram"
            name="instagram"
            register={register}
            errors={errors}
            className="mb-4"
          />
        </div>
        <Title text={'パスワード'}></Title>

        <div className="mb-16">
          <TextField
            label="パスワード"
            name="password"
            type="password"
            register={register}
            errors={errors}
            required
            className="mb-4"
          />
        </div>

        <div className="mb-16">
          <button className="w-52 text-center p-2 mx-auto block bg-orange font-bold rounded-md text-white">
            次へ
          </button>
        </div>
      </form>
      {isPaymentDialog && (
        <div className="fixed bg-black/50 top-0 left-0 h-screen w-screen flex justify-center items-center">
          <div className="p-12 bg-center bg-white rounded-md w-11/12 max-w-3xl">
            {amount && monthlyAmount && paymentIntentId && (
              <Elements
                stripe={stripePromise}
                options={{
                  appearance: {
                    theme: 'stripe',
                  },
                  clientSecret: paymentIntentId,
                }}
              >
                <StripeCheckoutForm
                  amount={amount}
                  monthlyAmount={monthlyAmount}
                  paymentIntentId={paymentIntentId}
                  successFunc={(pi) => registerUser(pi)}
                  closeFunc={() => {
                    setIsPaymentDialog(false)
                  }}
                ></StripeCheckoutForm>
              </Elements>
            )}
          </div>
        </div>
      )}
    </>
  )

  return <MainLayout title="スポンサー登録" content={content}></MainLayout>
}

export default RegisterInputPage
